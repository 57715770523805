import Vue from "vue/dist/vue";
import axios from "axios";
import VueAxios from "vue-axios";

import config from "@/config";

Vue.use(VueAxios, axios);

export default (objConfigParam) => {
  const objConfig = objConfigParam || {};

  let baseUrl = "";
  if (!config.api.baseUrl) {
    baseUrl += window.location.origin;
  }
  baseUrl += config.api.baseUrl;

  const instance = Vue.axios.create({
    withCredentials: true,
    baseURL: baseUrl,
    timeout: objConfig.timeout || 60000,
  });

  if (objConfig?.csrf_exempt !== true) {
    instance.defaults.xsrfHeaderName = "X-CSRFToken";
    instance.defaults.xsrfCookieName = "csrftoken";
  } else {
    instance.defaults.withCredentials = false;
    instance.defaults.xsrfHeaderName = null;
    instance.defaults.xsrfCookieName = null;
  }

  return instance;
};
