import store from "./store";

// Définit le pays selon la fin de l'url (.fr ou .de)
export function getCountry() {
  let country = "fr";
  if (window.location.hostname !== "localhost") {
    country = window.location.hostname.split(".").pop();
  }
  return country;
}

export default {
  api: {
    baseUrl: `${store.getters.baseUrl}/api`,
  },
  loginUrl: `${store.getters.baseUrl}/api/login/`,
  logoutUrl: `${store.getters.baseUrl}/api/logout/`,
  urlFr: {
    lde: "https://www.LDE.fr/",
    cristalCommandes: "https://numerique.LDE.fr/",
    extranet: "https://extranet.LDE.fr/external_login",
    hotline: "https://recette-hotline.LDE.fr/scenarisation/viewer/",
    hub: "https://www.portail-univers-cristal.lde.fr/",
    faq: "https://faq.LDE.fr/",
    faqTelechargements: "https://faq.LDE.fr/#/faq/Téléchargement",
    faqVideo: "https://faq.LDE.fr/#/faq/Vidéo",
    outilsChoix: "https://cas.LDE.fr/demoediteur",
    periodiques: "https://periodiques.LDE.fr/cas/login",
  },
  urlDe: {
    // TODO: mettre lien de la prod quand on en aura un
    cristalCommandes: "https://api-recette-cristalcommandes-de.LDE.fr/",
    extranet: "https://www.LDE-online.net/",
    hub: "https://www.hub.LDE.de/",
    poplab: "https://poplab.schule/",
  },
  mailsFr: {
    lde: "accueil@LDE.fr",
    dpo: "dpo@LDE.fr",
  },
  mailsDe: {
    lde: "service@LDE.de",
  },
  allServices: [
    {
      slug_service: "hub",
      nomFR: "Hub",
      nomDE: "Hub",
      componentSuffix: "Hub",
    },
    {
      slug_service: "cristal_commandes",
      nomFR: "Cristal&nbsp;: Numérique",
      nomDE: "Cristal: Digital",
      componentSuffix: "CristalCommandes",
    },
    // TODO: remettre quand on intégrera PopLab dans le Hub
    // {
    //   slug_service: "poplab",
    //   nomFR: "PopLab",
    //   nomDE: "PopLab",
    //   componentSuffix: "PopLab",
    // },
    {
      slug_service: "cristal_web",
      nomFR: "Stock, distribution & affectation",
      nomDE: "Bestand, Vertrieb und Zuweisungen",
      componentSuffix: "CristalWeb",
    },
    {
      slug_service: "extranet",
      nomFR: "Livres & fournitures",
      nomDE: "Bücher und Schulbedarf",
      componentSuffix: "Extranet",
    },
    {
      slug_service: "periodiques",
      nomFR: "Périodiques",
      componentSuffix: "Periodiques",
    },
    {
      slug_service: "faq",
      nomFR: "Foire aux questions",
      componentSuffix: "Faq",
    },
    {
      slug_service: "hotline",
      nomFR: "Hotline",
      componentSuffix: "Hotline",
    },
    {
      slug_service: "outils_de_choix",
      nomFR: "Outils d'aide au choix",
      componentSuffix: "OutilsChoix",
    },
  ],
  servicesInDropdown: ["faq", "telechargement", "video", "statuts_services"],
};
